.mainCard,
.mainPane {
  position: relative;
}

.carousel:hover {
  cursor: grab;
  background-color: rgba(248, 250, 250, 0.705);
}

@media only screen and (max-width: 500px) {
  .itemsPane,
  .allItemsPane {
    flex-direction: column;
    width: 100%;
    gap: 25px;
    padding: 8%;
  }
  .balancePane,
  .allAccountsPane {
    flex-direction: column;
    width: 100%;
  }
}
