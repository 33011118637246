@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

table,
thead,
tbody,
tfoot,
th,
td,
tr {
  /* border: unset !important;
  padding: unset !important;
  margin: unset !important; */
  border-spacing: 0;
  width: 100%;
  height: 100%;
}

/*
  Remove setas dos inputs de número
*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
