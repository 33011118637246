.App {
  height: 100vh;
  width: 100%;
  display: flex;
  scrollbar-gutter: stable both-edges !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #857d8b #e8e8e8;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #e8e8e8;
}

*::-webkit-scrollbar-thumb {
  background-color: #857d8b;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
}

.custom-dots {
  margin-top: 100px;
}

@media only screen and (max-width: 500px) {
  .vitaUserHeader,
  .caretDownHeader {
    display: none;
  }
  .companyName {
    font-size: 4.2vw;
  }
  .filterButton {
    min-width: 105px;
    min-height: 40px;
    font-size: 2.8vw;
  }

  .journalButton {
    font-size: 2.8vw;
  }

  .filterStatus {
    font-size: 3vw;
  }

  .calendarPane {
    flex-direction: column;
  }

  .termsPane {
    height: 320px;
  }
  .userPane {
    height: 332px;
  }
  .companyPane {
    height: 332px;
  }
}

.cardCompany:hover {
  background-color: #b8c4e48c;
}

/* .expiredDate:hover {
  background-color: #857d8b;
} */

.maincards {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cornerDialogPane {
  display: flex;
  flex-direction: column;
  height: fit-content;
  max-height: 150px;
}

.statement-entry:hover {
  background-color: #f5fbf8;
}
.statement-entry-selected {
  background-color: #f0eded;
}

.duplicated-statement-entry:hover {
  cursor: initial;
}
.duplicated-statement-entry {
  background-color: #ffdbdb !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
}

.flipped,
.flipped .entryTable {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg); /* IE 9 */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
}

@media only screen and (max-width: 1024px) {
  .maincards {
    flex-direction: column;
  }
  .dashboardPane {
    width: 75%;
  }
}

@media only screen and (max-width: 700px) {
  .categoryFamilyPane {
    width: 700px;
  }
  .banksTable {
    width: 800px;
  }
}

@media only screen and (max-width: 720px) {
  .dashboardPane {
    width: 98%;
  }
  .reconciliation-main-pane {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .vita-pane {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    order: 0;
  }
  .reconciliation-pane {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    order: 2;
  }
  .statement-pane {
    width: 80%;
    margin-top: 10px;
    margin-bottom: 10px;
    order: 1;
  }
}

@media only screen and (max-width: 455px) {
  .tabList {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .individualTabs {
    width: 150px;
  }
  .categoryFamilyPane {
    width: 700px;
  }
  .box1 {
    order: 2;
  }
  .box2 {
    order: 1;
  }
}
