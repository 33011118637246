.journalEntryTypeTitle {
  display: flex;
  width: fit-content;
  padding: 16px;
  border-radius: 6px;
  background-color: #f1f1f1;
}

.dialogWrapper {
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 16px;
}

.formArea {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.accordion {
  border-radius: 4px;
  cursor: pointer;
}
